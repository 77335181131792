<template>
	<div class="card-payment" :class="{ 'card-payment-error': cardPaymentError, submitting: isFormSubmitting }">
		<div class="transaction-form card-payment-bg" :class="{ 'border rounded-lg pl-3': card }">
			<div v-show="stripeReady" class="form-bar my-0">
				<div class="form-border-radius no-gutters my-2">
					<div class="payment-element-wrapper" id="stan-flexible-payment-element"></div>
				</div>
			</div>
			<div v-show="!stripeReady" style="height: 120px">
				<div class="loader-wrapper">
					<div class="loader-block">
						<HollowDotsSpinner
							class="d-flex align-items-center"
							:animation-duration="1000"
							:dot-size="14"
							:dots-num="3"
							:color="primaryColor"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-html="resultErrorMessage" class="error-msg"></div>
	</div>
</template>

<script>
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import { getThemeColors } from '~/stan-vue-shared/components/utils.js'

	const loadStripe = async () => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = '//js.stripe.com/v3/'
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}
	export default {
		name: 'StanFlexiblePaymentElement',
		components: {
			HollowDotsSpinner,
		},
		props: {
			isPaid: { type: Boolean, default: false },
			pageId: { type: Number },
			store: { type: Object, default: () => {} },
			fcpComplete: { type: Boolean, default: false },
			isFormSubmitting: { type: Boolean },
			resellRightsUuid: { type: String },
			affiliateCode: { type: String },
			theme: { type: String },
			product: { type: Object, default: () => {} },
			currency: { type: String },
			triggerSubmit: {
				type: Function,
			},
			analyticsProps: {
				type: Object,
				default: () => {},
			},
			fanName: { type: String, default: '' },
			fanEmail: { type: String, default: '' },
			stripeProcessingAccount: { type: String },
			stripeProcessingAccountType: { type: String },
		},
		data() {
			return {
				name: 'stripe',
				stripeReady: false,
				cardError: null,
				card: null,
				brand: null,
				stripe: null,
				paymentData: null,
				resultErrorMessage: '',
				elements: null,
				paymentRequestElement: null,
				payingWithWallet: false,
				paymentRequest: null,
			}
		},
		emits: ['paymentData', 'getCard', 'paymentSuccess', 'paymentError', 'paymentIntentId', 'gatewayAvailable'],
		computed: {
			cardPaymentError() {
				return this.resultErrorMessage !== '' || this.cardError
			},
			primaryColor() {
				return this.store?.data?.primary_color || '#5383ff'
			},
			secondaryColor() {
				return this.store?.data?.secondary_color
			},
		},
		async mounted() {},
		methods: {
			async initStripe() {
				await loadStripe()
				if (this.isPaid) {
					await loadCaptcha(this.$config.captcha_site_key)
					grecaptcha.enterprise.ready(() => {
						this.createPaymentData(this.pageId, true)
					})
				}
			},
			async createPaymentData(pageId = this.page_id, initializeCardField = false) {
				const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
					action: 'CreatePaymentIntent',
				})
				const analyticsData = await this.$getAnalyticsData({
					meta: { username: this.$route.params.username },
					props: this.analyticsProps,
				})

				const payload = {
					page_id: pageId ? pageId : this.pageId,
					resell_rights_uuid: this.resellRightsUuid,
					affiliate_code: this.affiliateCode,
					affiliated_username: this.$route.query?.su || this.$sessionStorage.getItem('LastVisitStore'),
					token: token,
					analytics_meta: analyticsData.meta,
					analytics_props: analyticsData.props,
					mode: 'stan-flexible-payment-element',
					stripe_processing_account_type: this.stripeProcessingAccountType,
				}
				this.$axios
					.post('v1/integrations/stripe/create-payment-intent', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						this.paymentData = response.data
						const self = this
						this.$emit('paymentData', { name: 'stan-flexible-payment', data: this.paymentData })
						setTimeout(() => {
							self.$emit('gatewayAvailable', {
								name: 'stan-flexible-payment',
								available: true,
								isConnect: self.paymentData.transaction_origin === 'user',
							})
						}, 20)
						if (initializeCardField) {
							this.createCardField(this.paymentData)
						}
					})
					.catch(error => {
						if (error.request.status == 401) {
							this.$notify({
								group: '1',
								title: 'Oops! Something went wrong.',
								text: 'Try refreshing or using a different browser.',
								type: 'error',
							})
						} else {
							this.$notify({
								group: '1',
								title: '@' + this.$route.params.username + ' has not yet setup their payment flow!',
								text: 'Please let them know to setup their Stan payouts so they can start accepting your payments!',
								type: 'error',
							})
							this.$sentry.captureException(error)
						}
						this.paymentData = null
						console.error(error)
						this.$emit('paymentData', { name: 'stan-flexible-payment', data: null })
					})
			},
			createCardField(pi) {
				const options = pi.transaction_origin === 'user' && pi.charge_type === 'direct' ? { stripeAccount: pi.account_id } : {}

				const stripe_key = this.stripeProcessingAccount || process.env.NUXT_ENV_STRIPE_PK

				this.stripe = Stripe(stripe_key, options)

				const themeColors = getThemeColors(this.theme, this.primaryColor, this.secondaryColor)

				const appearance = {
					theme: 'stripe',
					variables: {
						fontSizeBase: '14px',
						focusBoxShadow: 'transparent',
						colorPrimary: this.primaryColor,
						colorBackground: themeColors.secondaryColor,
						colorText: themeColors.primaryTextColor,
						colorTextSecondary: themeColors.secondaryTextColor,
						colorTextPlaceholder: themeColors.secondaryTextColor,
					},
				}
				switch (this.theme) {
					case 'kels':
						appearance.variables.theme = 'none'
						appearance.variables.borderRadius = '0px'
						break
					case 'moderno':
					case 'tyla':
						appearance.variables.borderRadius = '0px'
						break
					case 'stone':
						appearance.variables.borderRadius = '0px'
						break
					case 'nightview':
						appearance.variables.borderRadius = '10px'
						break
					case 'eclipse':
						appearance.variables.borderRadius = '10px'
						appearance.variables.colorPrimary = '#ffffff'
						appearance.variables.colorText = '#000000'
						appearance.variables.colorTextPlaceholder = '#999'
						appearance.variables.colorBackground = '#ffffff'
						appearance.variables.colorTextSecondary = '#999'
						appearance.variables.colorIconTab = this.primaryColor
						appearance.variables.colorIconTabSelected = this.primaryColor
						appearance.rules = {
							'.Label': {
								color: '#ffffff',
							},
							'.TabLabel': {
								color: '#999',
							},
							'.TabLabel--selected': {
								color: themeColors.primaryTextColor,
							},
						}
						break
					default:
						break
				}
				if (!appearance.rules) appearance.rules = {}
				appearance.rules['.RedirectText'] = {
					fontSize: '0px',
				}

				appearance.rules['.Tab--selected'] = {
					color: this.primaryColor,
					borderColor: themeColors.secondaryColor,
				}
				appearance.rules['.Tab--selected'] = {
					color: themeColors.primaryColor,
					borderColor: themeColors.secondaryColor,
					boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${themeColors.primaryColor}`,
				}

				appearance.rules['.Input'] = {
					padding: '16px',
					lineHeight: '18px',
				}

				this.elements = this.stripe.elements({
					clientSecret: pi.secret,
					loader: 'auto',
					appearance,
				})

				const paymentElement = this.elements.create('payment', {
					wallets: {
						applePay: 'never',
						googlePay: 'never',
					},
					defaultValues: {
						billingDetails: {
							name: this.fanName,
							email: this.fanEmail,
						},
					},
					fields: {
						billingDetails: {
							name: 'never',
							email: 'never',
						},
					},
				})
				if (document.getElementById('stan-flexible-payment-element')) paymentElement.mount('#stan-flexible-payment-element')

				this.card = paymentElement
				this.$emit('getCard', paymentElement, this.stripe)
				this.stripeReady = true
			},
			async processPayment(secret, card, stripe) {
				if (this.payingWithWallet) return
				this.$emit('paymentInit')
				this.resultErrorMessage = ''
				this.cardError = null
				if (!stripe) {
					stripe = this.stripe
				}
				if (!card) {
					card = this.card
				}
				if (!secret) {
					secret = this.paymentData?.secret
				}
				// Trigger form validation and wallet collection
				const { error: submitError } = await this.elements.submit()
				if (submitError) {
					this.cardError = submitError.message
					this.$emit('paymentError', { message: submitError.message })
					return
				}
				// Get front-end url
				let frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				stripe
					.confirmPayment({
						clientSecret: secret,
						elements: this.elements,
						redirect: 'if_required',
						confirmParams: {
							return_url: `${frontendURI}${this.$route.params.username}/success?failure_url=${window.location.href}`,
							payment_method_data: {
								billing_details: {
									name: this.fanName,
									email: this.fanEmail,
								},
							},
						},
					})
					.then(result => {
						if (result.error) {
							this.resultErrorMessage = result.error.message
							this.$emit('paymentError', { message: result.error.message })
							return
						}
						this.$emit('paymentSuccess')
					})
					.catch(error => {
						console.log(error)
						this.$emit('paymentError', { message: error.message })
						this.$sentry.captureException(
							new Error('Could not process payment using Stripe', { extra: { error, paymentData: this.paymentData } })
						)
					})
			},
			updatePaymentData(payload) {
				const payload_use = {
					mode: 'stan-flexible-payment-element',
					payment_method_type: 'card',
					...payload,
				}
				return this.$axios
					.patch('v1/integrations/stripe/payment-intent', payload_use, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						if (!this.paymentData || response.data.id !== this.paymentData.id) {
							this.paymentData = response.data
						}
						await this.elements.fetchUpdates()
						this.$emit('paymentData', { name: 'stan-flexible-payment', data: this.paymentData })
						return response.data
					})
					.catch(error => {
						this.$notify({
							group: '1',
							title: 'Something went wrong',
							text: 'Please reach out to our support at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>',
							type: 'error',
						})
						this.$emit('paymentData', { name: 'stripe', data: null })
						this.$sentry.captureException(new Error('Could not update payment-intent', { extra: { error, payload } }))
						throw error
					})
			},
		},
		watch: {
			fcpComplete(isReady) {
				if (isReady) {
					this.$nextTick(async () => {
						await this.initStripe()
					})
				}
			},
			fanName: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								name: newVal,
							},
						},
					})
				},
			},
			fanEmail: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								email: newVal,
							},
						},
					})
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.payment-element-wrapper {
		overflow: unset;
		margin: 10px 0;
	}

	#stan-flexible-payment-element::v-deep {
		margin: -4px;
		& > div {
			width: calc(100% - 2px);
			iframe {
				margin: 1px !important;
				width: 100% !important;
			}
		}
	}

	@supports (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			-webkit-appearance: -apple-pay-button;
			width: 100%;
			height: 45px;
			:hover {
				cursor: pointer;
				filter: brightness(1.2);
			}
		}
		.apple-pay-button-black {
			-apple-pay-button-style: black;
		}
		.apple-pay-button-white {
			-apple-pay-button-style: white;
		}
		.apple-pay-button-white-with-line {
			-apple-pay-button-style: white-outline;
		}
	}

	@supports not (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			background-size: 100% 60%;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 5px;
			padding: 0px;
			box-sizing: border-box;
			min-width: 200px;
			min-height: 32px;
			max-height: 64px;
			width: 100%;
		}
		.apple-pay-button-black {
			background-image: -webkit-named-image(apple-pay-logo-white);
			background-color: black;
		}
		.apple-pay-button-white {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
		}
		.apple-pay-button-white-with-line {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
			border: 0.5px solid black;
		}
	}
	#payment-request-element {
		height: 45px;
		background: #2c2e2f;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 25px;
		}
		&:hover {
			cursor: pointer;
			filter: brightness(1.2);
		}
	}
</style>
<style>
	.grecaptcha-badge {
		display: none !important;
	}
</style>
