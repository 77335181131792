export default {
	computed: {
		userPrimaryColor() {
			return (
				(this.page?.data && this.page?.data.primary_color) ||
				(this.store.data && this.store.data.primary_color) ||
				this.$store.getters.getUserPrimaryColor
			)
		},
		userSecondaryColor() {
			return (
				(this.page?.data && this.page?.data.secondary_color) ||
				(this.store.data && this.store.data.secondary_color) ||
				this.$store.getters.getUserSecondaryColor
			)
		},
		userPrimaryFont() {
			return (
				(this.page?.data && this.page?.data.primary_font) ||
				(this.store.data && this.store.data.primary_font) ||
				this.$store.getters.getUserPrimaryFont
			)
		},
		userBackground() {
			return { background: this.userPrimaryColor }
		},
		product() {
			return this.page.data.product
		},
		storeTheme() {
			if (this.$route.query?.theme) {
				return this.$route.query.theme
			}
			return this.store.data.theme || 'default'
		},
		deviceStyle() {
			return this.$mobile.isIosDevice() ? { 'padding-bottom': '55px' } : {}
		},
	},
	data() {
		return {
			isLoaded: false,
			formSubmitting: false,
		}
	},
}
